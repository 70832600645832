export const sidebarConfig = {
  "/docs/standard/": [
    {
      text: "项目规范",
      children: [
        "/docs/standard/java/java",
        "/docs/standard/git/git",
        "/docs/standard/database/database",
        // "/docs/standard/interface/pagination-api",
        // "/docs/standard/interface/api-response",
        "/docs/standard/interface/interface",
        "/docs/standard/redis/redis-spec",
        "/docs/standard/java/ide"
      ]
    }
  ],
  "/docs/tutorial/": [
    {
      text: "开发手册",
      children: [
        "/docs/tutorial/develop/develop",
        "/docs/tutorial/quickstart/quickstart"
      ]
    }
  ],
  "/docs/tutorial/plugins/": [
    {
      text: "插件使用说明",
      children: [
        "/docs/tutorial/plugins/lock",
        "/docs/tutorial/plugins/reSubmit",
        "/docs/tutorial/plugins/log",
        "/docs/tutorial/plugins/exception",
        "/docs/tutorial/plugins/encrypted",
        "/docs/tutorial/plugins/sensitive",
        "/docs/tutorial/plugins/i18n",
        "/docs/tutorial/plugins/fed-i18n",
        "/docs/tutorial/plugins/validation",
        "/docs/tutorial/plugins/ruleCode",
        "/docs/tutorial/plugins/database",
        "/docs/tutorial/plugins/ip2region",
        "/docs/tutorial/plugins/ShardingJdbc",
        "/docs/tutorial/plugins/openAPI",
        "/docs/tutorial/plugins/tenant",
        "/docs/tutorial/plugins/permission",
        "/docs/tutorial/plugins/tool",
        "/docs/tutorial/plugins/dataCache",
        "/docs/tutorial/plugins/api",
        "/docs/tutorial/plugins/codeGenerate",
        "/docs/tutorial/plugins/global-tx",
        "/docs/tutorial/plugins/xxl-job",
        "/docs/tutorial/plugins/event",
        "/docs/tutorial/plugins/oss",
        "/docs/tutorial/plugins/sysDict",
        "/docs/tutorial/plugins/jasypt",
        "/docs/tutorial/plugins/gateway",
        "/docs/tutorial/plugins/sysParam",
        "/docs/tutorial/plugins/protect",
        "/docs/tutorial/plugins/authLogin",
        "/docs/tutorial/plugins/permissionShare",
        "/docs/tutorial/plugins/userPermission",
        "/docs/tutorial/plugins/login_attribute",
        "/docs/tutorial/plugins/erpSso",
        "/docs/tutorial/plugins/corp",
        "/docs/tutorial/plugins/superAdminPermission",
        "/docs/tutorial/plugins/fieldFill"
      ]
    }
  ],
  "/docs/tutorial/design/": [
    {
      text: "设计方案",
      children: [
        "/docs/tutorial/design/logs",
        "/docs/tutorial/design/validException",
        "/docs/tutorial/design/xxl-job",
        "/docs/tutorial/design/ruleCode",
        "/docs/tutorial/design/ossFileDelete",
        "/docs/tutorial/design/oauth-2rd",
        "/docs/tutorial/design/dataPermission",
        "/docs/tutorial/design/tenant",
        // '/docs/tutorial/design/openAPIPermission',
        "/docs/tutorial/design/openAPISign",
        "/docs/tutorial/design/sensitive",
        "/docs/tutorial/design/encrypted",
        "/docs/tutorial/design/cache",
        "/docs/tutorial/design/apiPermission",
        "/docs/tutorial/design/cacheCode",
        "/docs/tutorial/design/snowflake",
        "/docs/tutorial/design/sysDict",
        "/docs/tutorial/design/poi-tl",
        "/docs/tutorial/design/rocketmq",
        "/docs/tutorial/design/sysParam",
        "/docs/tutorial/design/i18n",
        "/docs/tutorial/design/ruleEngine",
        "/docs/tutorial/design/tablefield",
        "/docs/tutorial/design/plaformApplication",
        "/docs/tutorial/design/jasypt",
        "/docs/tutorial/design/j2cache",
        "/docs/tutorial/design/dataPermissionShare",
        "/docs/tutorial/design/ossFileManage",
        "/docs/tutorial/design/message",
        "/docs/tutorial/design/excelUtil",
        "/docs/tutorial/design/websocket",
        "/docs/tutorial/design/roleType",
        "/docs/tutorial/design/userChatApi",
        "/docs/tutorial/design/messageTag",
        "/docs/tutorial/design/userGroup",
        "/docs/tutorial/design/tokenExpire",
        "/docs/tutorial/design/orgPermission",
        "/docs/tutorial/design/documentMulti",
        "/docs/tutorial/design/userPasswordConfig",
        "/docs/tutorial/design/multiTerminalLogin",
        "/docs/tutorial/design/translationService",
        "/docs/tutorial/design/singleService",
        "/docs/tutorial/design/userOnline",
        "/docs/tutorial/design/networkConfig",
        "/docs/tutorial/design/j2cacheExpire",
        "/docs/tutorial/design/loginAuth"
      ]
    }
  ],
  "/docs/tutorial/docker/": [
    {
      text: "部署说明",
      children: [
        "/docs/tutorial/docker/deploy",
        "/docs/tutorial/docker/nacos",
        "/docs/tutorial/docker/otter-log-service",
        "/docs/tutorial/docker/otter-message-service",
        "/docs/tutorial/docker/otter-oss-service",
        "/docs/tutorial/docker/otter-security-service",
        "/docs/tutorial/docker/otter-sequence-service",
        "/docs/tutorial/docker/otter-system-service",
        "/docs/tutorial/docker/otter-uaa-service",
        "/docs/tutorial/docker/otter-xxl-job-service"
      ]
    }
  ],
  "/docs/tutorial/upgrade/": [
    {
      text: "版本升级",
      children: [
        "/docs/tutorial/upgrade/otter-V5_1_2",
        "/docs/tutorial/upgrade/otter-V5_1_1",
        "/docs/tutorial/upgrade/otter-V5_1_0",
        "/docs/tutorial/upgrade/otter-V5_0_17",
        "/docs/tutorial/upgrade/otter-V5_0_16",
        "/docs/tutorial/upgrade/otter-V5_0_15",
        "/docs/tutorial/upgrade/otter-V5_0_14",
        "/docs/tutorial/upgrade/otter-V5_0_13",
        "/docs/tutorial/upgrade/otter-V5_0_12",
        "/docs/tutorial/upgrade/otter-V5_0_11",
        "/docs/tutorial/upgrade/otter-V5_0_10",
        "/docs/tutorial/upgrade/otter-V5_0_9",
        "/docs/tutorial/upgrade/otter-V5_0_8_3",
        "/docs/tutorial/upgrade/otter-V5_0_8_2",
        "/docs/tutorial/upgrade/otter-V5_0_8_1",
        "/docs/tutorial/upgrade/otter-V5_0_8",
        "/docs/tutorial/upgrade/otter-V5_0_7",
        "/docs/tutorial/upgrade/otter-V5_0_6",
        "/docs/tutorial/upgrade/otter-V5_0_5",
        "/docs/tutorial/upgrade/otter-V5_0_4",
        "/docs/tutorial/upgrade/otter-V5_0_3",
        "/docs/tutorial/upgrade/otter-V5_0_2",
        "/docs/tutorial/upgrade/otter-V5_0_1",
        "/docs/tutorial/upgrade/otter-V5_0_0",
        "/docs/tutorial/upgrade/otter-V4_6_1",
        "/docs/tutorial/upgrade/otter-V4_6_0",
        "/docs/tutorial/upgrade/otter-V4_5_1",
        "/docs/tutorial/upgrade/otter-V4_5_0",
        "/docs/tutorial/upgrade/otter-V4_0_0",
        "/docs/tutorial/upgrade/otter-V3_0_5"
      ]
    }
  ],
  "/docs/changelog/": [
    {
      text: "更新日志",
      depth: 2,
      collapse: false,
      children: ["/docs/changelog/rearend", "/docs/changelog/frontend"]
    }
  ],
  "/requirements/": [
    {
      text: "需求说明手册",
      children: [
        "/requirements/按钮及分类管理/按钮及分类管理需求说明.md",
        "/requirements/单据管理/单据管理需求说明.md",
        "/requirements/功能及数据权限/功能及数据权限需求说明.md",
        "/requirements/平台应用管理/平台应用管理需求说明.md",
        "/requirements/任务调度中心/任务调度中心XXLJOB需求说明.md",
        "/requirements/日程管理/日程管理需求说明.md",
        "/requirements/日志/审计日志需求说明.md",
        "/requirements/日志/otter微服务日志模块需求说明.md",
        "/requirements/首屏报表/首屏报表需求说明.md",
        "/requirements/首屏报表/首屏报表需求说明1222.md",
        "/requirements/系统参数/系统参数需求说明.md",
        "/requirements/消息模板/消息模板管理需求说明.md",
        "/requirements/消息中心/IM消息中心需求说明桌面端.md",
        "/requirements/应用管理/应用管理需求说明.md",
        "/requirements/找回密码/找回密码需求说明.md",
        "/requirements/字典管理/字典管理需求说明.md",
        "/requirements/租户管理/租户管理需求说明.md",
        "/requirements/word转html邮件工具/word转html邮件工具需求说明.md"
      ]
    }
  ],
  "/docs/userbookall/": [
    {
      text: "Otter 用户使用手册",
      children: ["/docs/userbookall/userbookall"]
    }
  ],
  "/docs/userbook/": [
    {
      text: "Otter 用户使用手册 分模块",
      depth: 1,
      children: [
        {
          link: "/docs/userbook/organization",
          text: "组织管理"
        },
        {
          link: "/docs/userbook/usermanage",
          text: "用户管理"
        },
        {
          link: "/docs/userbook/menumanage",
          text: "菜单管理"
        },
        {
          link: "/docs/userbook/appmanage",
          text: "应用管理"
        },
        {
          link: "/docs/userbook/authoritymanagement",
          text: "权限管理"
        },
        {
          link: "/docs/userbook/tenantmanage",
          text: "租户模块"
        },
        {
          link: "/docs/userbook/codemodel",
          text: "编码模块"
        },
        {
          link: "/docs/userbook/messagemodel",
          text: "消息模板"
        },
        {
          link: "/docs/userbook/taskscheduling",
          text: "任务调度"
        },
        {
          link: "/docs/userbook/auditlogs",
          text: "审计日志"
        },
        {
          link: "/docs/userbook/annexmanage",
          text: "附件管理"
        },
        {
          link: "/docs/userbook/documentattachment",
          text: "单据附件管理"
        },
        {
          link: "/docs/userbook/dictionarymanagement",
          text: "字典管理"
        },
        {
          link: "/docs/userbook/systemparameters",
          text: "系统参数"
        },
        {
          link: "/docs/userbook/eventservice",
          text: "事件服务"
        },
        {
          link: "/docs/userbook/roleclassification",
          text: "角色分类"
        },
        {
          link: "/docs/userbook/multilanguage",
          text: "多语言管理"
        },
        {
          link: "/docs/userbook/buttonauth",
          text: "按钮权限前端配置说明"
        },
        {
          link: "/docs/userbook/uimanage",
          text: "UI主题管理"
        },
        {
          link: "/docs/userbook/designer",
          text: "表单设计器"
        },
        {
          link: "/docs/userbook/cworld",
          text: "cworld大屏"
        }
      ]
    }
  ],
  "/docs/tutorial/interfacedoc": [
    {
      text: "otter-security-api接口",
      children: [
        "/docs/tutorial/interfacedoc/security/versionPermission",
        "/docs/tutorial/interfacedoc/security/corp",
        "/docs/tutorial/interfacedoc/security/corpVersionRelation",
        "/docs/tutorial/interfacedoc/security/corpDept",
        "/docs/tutorial/interfacedoc/security/corpUserRole",
        "/docs/tutorial/interfacedoc/security/documentPermission",
        "/docs/tutorial/interfacedoc/security/role",
        "/docs/tutorial/interfacedoc/security/socialUser",
        "/docs/tutorial/interfacedoc/security/userLogin"
      ]
    },
    {
      text: "otter-message-api接口",
      children: [
        "/docs/tutorial/interfacedoc/message/verificationCode",
        "/docs/tutorial/interfacedoc/message/messageRecord"
      ]
    },
    {
      text: "otter-uaa-api接口",
      children: [
        "/docs/tutorial/interfacedoc/uaa/login"
      ]
    }
  ]
}
